import React from "react"
import Link from "./link"
import parse from "html-react-parser"
import { formatEventDate } from "../utils/date-utils"
import { Helmet } from "react-helmet"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const EventCard = ({
  id = '',
  title = '',
  uri = '',
  excerpt = '',
  eventMeta = {},
  featuredImage = {},
  pageVariables = {}
}) => {

  const image = featuredImage && getImage(featuredImage.node.localFile)
  
  return (
    <div className="event-box mb-4">
      {eventMeta?.statusText && <div className="status-text">{eventMeta?.statusText}</div>}
      <GatsbyImage image={image} />
      <div className="h-100 d-flex justify-content-end flex-column">
        <div className="heading-overlay p-4 text-white mb-4">
          <h2 className="text-white">{title}</h2>
            {!eventMeta?.hideDates ? <p>{formatEventDate(eventMeta.startDate, eventMeta.endDate)}</p> : <p></p>}
            <p className="d-none d-lg-block">{parse(excerpt)}</p>
          </div>
        </div>
      <Link to={uri} className="stretched-link"></Link>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Event",
            "name": title,
            "description": excerpt.replace(/(<([^>]+)>)/gi, ""),
            "image": featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.images?.fallback?.src,
            "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled",
            "startDate": eventMeta?.startDate,
            "endDate": eventMeta?.endDate,
            "archive": eventMeta?.archive,
            "statusText": eventMeta?.statusText,
            "location": [
              {
                "@type": "Place",
                "name": "Blackgang Chine",
                "address": {
                  "streetAddress": "Blackgang Chine",
                  "addressLocality": "Nr Ventnor",
                  "addressRegion": "Isle of Wight",
                  "postalCode": "PO38 2HN",
                  "addressCountry": "United Kingdom"
                }
              }
            ],
            "organizer": {
              "@type": "Organization",
              "name": "Blackgang Chine",
              "url": "https://blackgangchine.com"
            }
          })}
        </script>
      </Helmet>
    </div>
  )
}

export default EventCard