import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import EventsGrid from "../components/events-grid"
import Title from "../components/title"
import { parseISO } from "date-fns"

export const query =  graphql`
  query TEMPLATE_WP_CALENDAR_QUERY(
    $id: String!
  ) {
    wpPage(id: { eq: $id }) {
      ...pageData
      ...pageVariables
    }
    allWpEvent(
      sort: {
        fields: dateGmt
        order: DESC
      }
    ) {
      nodes {
        ...eventData
        ...eventVariables
        ...eventFeaturedImage
      }
    }
  }
`

const wpCalendarTemplate = ({ data = {} }) => {
  console.log(data)

  const { wpPage: page = {}, allWpEvent: { nodes: events = [] } } = data
  const { 
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {} 
  } = page
  const { title: metaTitle = '', metaDesc = '' } = smartcrawlSeo
  const image = pageMeta.headerImage && convertToBgImage(getImage(page.pageMeta.headerImage.localFile))

  events.sort(function(a, b) {
    let dateA = parseISO(a.eventMeta.startDate)
    let dateB = parseISO(b.eventMeta.startDate)
    return (dateA < dateB) ? -1 : ((dateA > dateB) ? 1 : 0)
  })
 
  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      {pageVariables.primaryColour && 
        <style dangerouslySetInnerHTML= {{__html: `
        :root {
          --bs-primary: ${pageVariables.primaryColour};
        }
        `}} />
      }
      <main>
        {image &&
          <BackgroundImage 
            {...image} 
            className="header-image"
          />
        }
        
        <Title title={page.title} />

        <div className="pt-5" dangerouslySetInnerHTML={{ __html: page.content }} />

        {events && events.length > 0
          ? <EventsGrid events={events} />
          : (
            <div className="text-center py-5">
              <h2>Events coming soon...</h2>
              <p>Currently we don't have any upcoming events. Keep watching our website and social media for updates on this years events calendar and even more exciting announcements…!</p>
            </div>
          )
        }

        
      </main>
    </React.Fragment>
  )
}

export default wpCalendarTemplate