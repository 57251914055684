import React, { useState } from "react"
import EventCard from "./event-card"

const EventsGrid = ({ events = [] }) => {

  const [showArchived, setShowArchived] = useState(false)

  const filterArchivedEvents = ({eventMeta}) => showArchived? eventMeta?.archive :!eventMeta?.archive;

  return (
    <div className="container">
      <div className="d-flex justify-content-end py-4">
        <div class="form-check form-switch"> 
          <input onChange={() => setShowArchived(!showArchived)} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          <label class="form-check-label" for="flexSwitchCheckDefault">Show Past Events</label>
        </div>
      </div>
      <div className="row">
        {events
          .filter(filterArchivedEvents)
          .map((node) => {
          const {
            id = '',
            title = '',
            uri = '',
            excerpt = '',
            eventMeta = {},
            featuredImage = {},
            pageVariables = {}
          } = node

          return (
            <div key={id} className="col-12 col-md-6">
              <EventCard
                id={id}
                title={title}
                uri={uri}
                excerpt={excerpt}
                eventMeta={eventMeta}
                featuredImage={featuredImage}
                pageVariables={pageVariables}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EventsGrid